import React from "react";
import styles from "./AppCarousel.module.scss";
import { Entry } from "contentful";
import { ContentSection } from "@tiicker/util/lib/contentful/types";
import { compact } from "lodash";
import { getImageReference } from "@tiicker/util/lib/contentful/helpers";
import SwiperCarousel from "components/Carousel/SwiperCarousel";

interface Props {
  entries?: Entry<ContentSection>[];
  includes: any;
}

const AppCarousel = (props: Props) => {
  const content =
    props.entries && props.entries.length > 0 ? props.entries[0] : undefined;
  const files = content?.fields.files || [];
  const iphoneFrameUrl = "/images/iphone-13.png";

  const images = compact(
    files.map((x) => getImageReference(x, props.includes))
  );

  const imageMapper = (image, index) => {
    return (
      <div key={`${index}`}>
        <div className={styles.AppCarousel__imageWrapper}>
          <img
            className={styles.AppCarousel__image}
            src={`${image.fields.file.url}`}
          />
          <img className={styles.AppCarousel__frame} src={iphoneFrameUrl} />
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className={styles.AppCarousel}>
        <SwiperCarousel
          slides={images.map(imageMapper)}
          spaceBetween={60}
          centeredSlides
          loop
          openSide="both"
          slidesPerView={7}
          breakPoints={{
            1600: {
              slidesPerView: 5,
            },
            1000: {
              slidesPerView: 3,
            },
            100: {
              slidesPerView: 1,
            },
          }}
          autoplay={{
            delay: 2500,
          }}
        />
      </div>
    </div>
  );
};

export default AppCarousel;
