import React, { useState } from "react";
import styles from "./App.module.scss";
import { GetStaticProps } from "next";
import { getContentfulClient } from "@tiicker/util/lib/contentful/client";
import {
  ContentfulContentType,
  ContentfulKey,
  GenericMarketingPage,
  PageSection,
  PageSectionKey,
} from "@tiicker/util/lib/contentful/types";
import { Entry, EntryCollection } from "contentful";
import { compact } from "lodash";
import {
  ContentSections,
  getImageReference,
  getItemReference,
  loadContentSections,
} from "@tiicker/util/lib/contentful/helpers";
import GenericMetaTags from "components/MetaTags/GenericMetaTags";
import ColorWrapper from "components/Home/ColorWrapper/ColorWrapper";
import NavigationBar from "components/Global/Navigation/NavigationBar/NavigationBar";
import FooterV2 from "components/FooterV2/FooterV2";
import Button from "components/core/Button/Button";
import Link from "next/link";
import AppCarousel from "components/AppCarousel/AppCarousel";
import Modal from "components/Modal/Modal";
import ContactForm from "components/ContactForm/ContactForm";
import { useFetchUser } from "source/hooks";
import StartEarningPerksMadi from "components/StartEarningPerksMadi/StartEarningPerksMadi";

interface Props {
  pageRecord: Entry<GenericMarketingPage> | null;
  slider: Entry<PageSection> | undefined;
  features: Entry<PageSection> | undefined;
  downloads: Entry<PageSection> | undefined;
  support: Entry<PageSection> | undefined;
  joinNowCTA: Entry<PageSection> | undefined;
  contentSections: ContentSections;
  mobileLanding: EntryCollection<GenericMarketingPage>;
}

const buttonSnippet = (
  <div className={styles.buttonWrapper}>
    <Link href="https://apps.apple.com/us/app/tiicker-own-brands-you-love/id1536483264">
      <a target="_blank">
        <img
          src="/images/available-on-the-app-store.png"
          className={styles.button}
        />
      </a>
    </Link>
    <Link href="https://play.google.com/store/apps/details?id=com.tiicker.tiickerandroid">
      <a target="_blank">
        <img src="/images/play-store-button.png" className={styles.button} />
      </a>
    </Link>
  </div>
);

const MobileAppDownloadPage = (props: Props) => {
  const [supportModal, setSupportModal] = useState<boolean>(false);
  const { user } = useFetchUser();

  return (
    <>
      {props.pageRecord && (
        <GenericMetaTags pageContent={props.pageRecord.fields} />
      )}
      <ColorWrapper>
        <NavigationBar />

        <div className="container">
          <div className={[styles.flexContainer, styles.flexCol].join(" ")}>
            <span className={styles.header}>
              {props.pageRecord?.fields.displayTitle}
            </span>
            <span className={styles.description}>
              {props.pageRecord?.fields.displayDescription}
            </span>
            {buttonSnippet}
          </div>
        </div>

        <div className={styles.centerModeParent}>
          {props.slider && (
            <AppCarousel
              entries={props.contentSections.sections[props.slider.sys.id]}
              includes={props.mobileLanding.includes}
            />
          )}
        </div>
      </ColorWrapper>

      <div className="container">
        {props.features && (
          <div
            className={[
              styles.flexContainer,
              styles.flexCol,
              styles.featureSection,
            ].join(" ")}
          >
            <h2>{props.features.fields.displayTitle}</h2>
            <div
              className={[styles.flexContainer, styles.featureParent].join(" ")}
            >
              {(
                props.contentSections.sections[props.features.sys.id] || []
              ).map((feature) => {
                return (
                  <div key={feature.sys.id} className={styles.feature}>
                    {feature.fields.image && (
                      <img src={feature.fields.image.fields.file.url} />
                    )}
                    <h5>{feature.fields.title}</h5>
                    <p>{feature.fields.textContent}</p>
                  </div>
                );
              })}
            </div>
          </div>
        )}

        <div className={styles.downloadsAndSupport}>
          {props.downloads && (
            <div
              className={[
                styles.downloads,
                styles.flexContainer,
                styles.flexCol,
              ].join(" ")}
            >
              <h2>{props.downloads.fields.displayTitle}</h2>
              <p>{props.downloads.fields.description}</p>
              {buttonSnippet}
            </div>
          )}
          {props.support && (
            <div
              className={[
                styles.support,
                styles.flexContainer,
                styles.flexCol,
              ].join(" ")}
            >
              <h4>{props.support.fields.displayTitle}</h4>
              <p>{props.support.fields.description}</p>
              <Button type="secondary" onClick={() => setSupportModal(true)}>
                {props.contentSections.sections[props.support.sys.id].length > 0
                  ? props.contentSections.sections[props.support.sys.id][0]
                      .fields.linkText
                  : "Contact Support"}
              </Button>
            </div>
          )}
        </div>
      </div>

      {props.joinNowCTA && (
        <StartEarningPerksMadi
          page={props.joinNowCTA}
          includes={props.contentSections.includes}
        />
      )}
      <Modal
        visible={supportModal}
        width={522}
        onClose={() => setSupportModal(false)}
        className={styles.supportModal}
      >
        <ContactForm user={user} afterSubmit={() => setSupportModal(false)} />
      </Modal>
      <FooterV2 />
    </>
  );
};

export const getStaticProps: GetStaticProps<Props> = async () => {
  const client = getContentfulClient("contentful");

  const mobileLanding = await client.getEntries<GenericMarketingPage>({
    content_type: ContentfulContentType.GenericMarketingPage,
    ["fields.key"]: ContentfulKey.MobileApp,
  });

  const pageRecord =
    mobileLanding.items.length > 0 ? mobileLanding.items[0] : null;

  const pageSections = compact(
    pageRecord?.fields.pageSections?.map((section) =>
      getItemReference(section, mobileLanding.includes)
    )
  );

  const slider = pageSections.find(
    (x) => x.fields.sectionKey === PageSectionKey.MobileAppSlider
  );

  const features = pageSections.find(
    (x) => x.fields.sectionKey === PageSectionKey.MobileAppFeatures
  );

  const downloads = pageSections.find(
    (x) => x.fields.sectionKey === PageSectionKey.MobileAppDownload
  );

  const support = pageSections.find(
    (x) => x.fields.sectionKey === PageSectionKey.MobileAppSupport
  );

  const joinNowCTA = pageSections.find(
    (x) => x.fields.sectionKey === PageSectionKey.JoinNowCTA
  );

  const contentSections = await loadContentSections(client, pageSections);

  return {
    props: {
      pageRecord,
      slider,
      features,
      downloads,
      support,
      joinNowCTA,
      contentSections,
      mobileLanding,
    },
    revalidate: 100,
  };
};

export default MobileAppDownloadPage;
